export const FirebaseConfig = {
	"projectId": "ridela",
	"appId": "1:268899244771:web:5f2226318e25234ee483d6",
	"databaseURL": "https://ridela-default-rtdb.firebaseio.com",
	"storageBucket": "ridela.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAoAaHLywyHyO521TdwEKnHx6Dz6O7DOxI",
	"authDomain": "ridela.firebaseapp.com",
	"messagingSenderId": "268899244771",
	"measurementId": "G-V4TL2QEDGH"
};